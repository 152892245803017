<template>
  <div>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <map-skeleton v-if="!business" />

    <v-overlay class="loading-center" :value="save">
      <v-progress-circular
        :width="3"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <v-container fluid>
      <v-row class="pa-0 ma-0">
        <h1 v-if="business">
          Ubicación de
          <span class="font-weight-light">{{ business.shortName }}</span>
        </h1>
      </v-row>
    </v-container>

    <div class="main-container" v-if="business">
      <v-container fluid class="fill-height pr-5 screen-width">
        <v-row class="pt-3 ml-2">
          <v-col md="12" cols="12" sm="12">
            <l-map
              @update:center="centerUpdated"
              class="fill-height"
              style="height: 50vh"
              :zoom="zoom"
              :center="center"
            >
              <l-tile-layer :url="url"></l-tile-layer>
              <l-marker
                :lat-lng="[center[0], center[1]]"
                @update:lat-lng="positionUpdated"
                :draggable="$store.state.user.type == 'sudo' ? true : false"
                :icon="getImage"
              >
                <l-popup v-if="$store.state.user.type == 'businessOwner'">
                  {{ business.zone }}!
                </l-popup>

                <l-tooltip v-else>
                  Mueva el marcador a la ubicación del comercio
                </l-tooltip>
              </l-marker>
              <v-geosearch
                v-if="$store.state.user.type == 'sudo'"
                :options="geosearchOptions"
              ></v-geosearch>
            </l-map>
          </v-col>
          <v-col v-if="$store.state.user.type == 'sudo'" cols="12" md="6">
            <h2>Latitud</h2>
            <input
              type="number"
              class="control-input control-input-number"
              placeholder="Latitud"
              v-model="center[0]"
            />
          </v-col>

          <v-col v-if="$store.state.user.type == 'sudo'" cols="12" md="6">
            <h2>Longitud</h2>
            <input
              type="number"
              class="control-input control-input-number"
              placeholder="Longitud"
              v-model="center[1]"
            />
          </v-col>

          <v-col cols="12">
            <h2>Dirección</h2>
            <textarea
              v-model="business.zone"
              placeholder="Dirección exacta del negocio"
              class="control-input"
              rows="3"
            ></textarea>
          </v-col>
        </v-row>

        <v-row class="pa-0 ma-0">
          <v-col cols="12">
            <v-divider></v-divider>
            <v-row justify="end">
              <v-btn
                class="save-btn"
                @click="updateBusinessInformation"
                color="primary"
                dark
              >
                Guardar
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import { mapState } from "vuex";
import {
  LMap,
  LTileLayer,
  LIcon,
  LMarker,
  LPopup,
  LTooltip,
} from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import mapSkeleton from "./skeleton/map-skeleton";
import axios from "axios";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import VGeosearch from "vue2-leaflet-geosearch";
import ledgerMixin from "@/mixins/ledgerMixin";

export default {
  name: "owner-map",
  mixins: [lottieSettings, ledgerMixin],
  components: {
    lottie: Lottie,
    LMap,
    LTileLayer,
    LIcon,
    LMarker,
    LPopup,
    mapSkeleton,
    LTooltip,
    VGeosearch,
  },
  data() {
    return {
      geosearchOptions: {
        showMarker: false,
        provider: new OpenStreetMapProvider(),
        animateZoom: true,
        autoClose: true,
        searchLabel: "Buscar comercio",
      },
      business: null,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 0,
      center: [47.41322, -1.219482],
      staticAnchor: [16, 37],
      iconSize: 32,
      icon: L.icon(this.getImage),
      snackbar: false,
      snackbarText: "",
      save: false,
      prevValue: {},
    };
  },
  watch: {
    selectedBusiness() {
      this.business = null;
      this.getBusinessData();
    },
    zoom() {
      if (this.zoom < 28) {
        setTimeout(() => {
          this.zoom += 5;
        }, 10);
      }
    },
  },
  computed: {
    ...mapState(["selectedBusiness"]),
    getSectionToken() {
      return new Promise((resolve, reject) => {
        fb.auth()
          .currentUser.getIdToken(true)
          .then((token) => {
            resolve(token);
          });
      });
    },
    // dynamicSize() {
    //   return [this.iconSize, this.iconSize * 1];
    // },
    // dynamicAnchor() {
    //   return [this.iconSize / 2, this.iconSize * 1];
    // },
    getImage() {
      return L.icon({
        iconUrl:
          this.business.logo.original ||
          require("../../assets/avatar-default.svg"),
        shadowUrl: require("../../assets/map-marker.png"),
        iconSize: [32, 32],
        shadowSize: [64, 64],
        iconAnchor: [-12, 56],
        shadowAnchor: [4, 62],
        popupAnchor: [-3, -76],
      });
    },
  },
  methods: {
    positionUpdated(e) {
      if (this.$store.state.user.type == "sudo") this.center = [e.lat, e.lng];
    },
    centerUpdated(e) {
      if (this.$store.state.user.type == "sudo") this.center = [e.lat, e.lng];
    },
    handleSuccess() {
      this.save = false;
      this.snackbar = true;
      this.snackbarText = "Información actualizada correctamente.";
    },
    handleFail() {
      this.save = false;
      this.snackbar = true;
      this.snackbarText = "Ocurrió un error inesperado, inténtelo nuevamente";
    },
    async updateBusinessInformation() {
      this.save = true;

      var businessRef = db
        .collection("businesses")
        .doc(this.selectedBusiness[".key"]);

      businessRef
        .update({
          zone: this.business.zone,
        })
        .then(async () => {
          if (this.$store.state.user.type == "sudo") {
            if (this.center[0] && this.center[1]) {
              let data = {
                lat: this.center[0],
                lng: this.center[1],
                businessId: this.selectedBusiness[".key"],
              };

              var httpBusinessesUpdateGeoPoint = fb
                .functions()
                .httpsCallable("httpBusinessesUpdateGeoPoint");
              httpBusinessesUpdateGeoPoint(data)
                .then((result) => {
                  this.handleSuccess();
                })
                .catch(() => {
                  this.handleFail();
                });
            } else {
              this.save = false;
              this.snackbarText = "Coordenadas no válidas";
              this.snackbar = true;
            }
          } else {
            this.handleSuccess();
          }

          this.createLedger({
            source_id: this.selectedBusiness[".key"],
            source_type: "businesses",
            currentValue: this.selectedBusiness,
            prevValue: this.prevValue,
          });
        })
        .catch(() => {
          this.handleFail();
        });
    },
    getBusinessData() {
      if (!this.selectedBusiness) this.$router.push({ path: "/" });

      this.business = this.selectedBusiness;
      this.center = [
        this.selectedBusiness.geoAddress.geopoint.latitude,
        this.selectedBusiness.geoAddress.geopoint.longitude,
      ];
      this.zoom = 1;
    },
  },

  mounted() {
    this.$store.state.visibleSearch = false;
    this.getBusinessData();
    this.prevValue = Object.assign({}, this.selectedBusiness);
  },
};
</script>

<style lang="scss">
#mapid {
  height: 40vh;
}
.leaflet-marker-icon {
  border-radius: 100%;
  background-color: #f96921;
  background-size: cover;
  animation-name: in;
  animation-duration: 3s;
}
.leaflet-shadow-pane {
  animation-name: in;
  animation-duration: 3s;
}
@keyframes in {
  0% {
    left: -200px;
    top: -100px;
  }
  25% {
    left: 0;
    top: 0;
  }
}
.save-btn {
  margin-top: 20px;
}
.vue2leaflet-map {
  z-index: 1;
}
</style>
